import React from 'react'
import Layout from '../components/Layouts/Layout'
import SEO from '../components/SEO'

const description = "Read the nitty gritty details for Creative Ilk, your guide to email and digital marketing."

const PrivacyPolicy: React.FunctionComponent = () => (
  <Layout title="Privacy Policy">
    <SEO title="Privacy Policy" description={description} />
    <p>Last updated June 2020</p>
    <p>
      This following document sets forth the Privacy Policy for the Creative Ilk website, https://creativeilk.com. Creative Ilk is bound by the Privacy Act 1988 (Cth), which sets out a number of principles concerning the privacy of individuals.
    </p>
    <h2>
      Collection of personal information
    </h2>
    <p>
      There are many aspects of the site that can be viewed without providing personal information, however, for access to future Creative Ilk features, you may be required to submit personally identifiable information. This may include, but is not limited to, data this website collects,
      by itself or through third parties, such as: cookies; usage data; email address; first name; last name; profession; state; country; date of birth; company name; username; and password. You may need to provide sensitive information in the recovery of a lost password.
    </p>
    <p>
      Personal data may be freely provided by the user or collected automatically while using this website.
    </p>
    <p>
      Creative Ilk and its third-party tools process personal data and use cookies or other identifiers, which are necessary for the website's functioning. By using creativeilk.com, you accept the use of cookies.
    </p>
    <h2>
      Sharing of your personal information
    </h2>
    <p>
      We may occasionally commission other companies to provide goods or services on our behalf. Creative Ilk takes reasonable steps to ensure any organisations that we hire are bound by confidentiality and privacy obligations in relation to the protection of your personal information.
    </p>
    <h2>
      Processing of personal information
    </h2>
    <p>
      Creative Ilk takes appropriate security measures to prevent unauthorised access to data. Data is available to persons involved with the operation of Creative Ilk including employees, contractors, and external parties. The updated list of these parties may be requested from Creative Ilk at any time.
    </p>
    <p>
      The data is processed at Creative Ilk's operating offices in Australia, and in any other places where the parties involved in the processing of data are located. Data transfers
      may involve transferring the user's data to a country other than their own, depending on the user's location.
    </p>
    <h2>
      Use of your personal information
    </h2>
    <p>
      For each visitor to the site, Creative Ilk expressively collect the following non-personally identifiable information, including but not limited to browser type, version, language, operating system, pagies viewed, page access times, referring website address. The collected information is used internally for monitoring web traffic, user behaviour, trends, and delivering personalised content to users.
    </p>
    <p>
      By signing up to receive marketing content (for email newsletters), the user's email adadress will be added to a contact list of those who may receive email messages containing information of commercial or promotional nature. cCreative Ilk may also collect data concerning the date and time when messages sent to users were viewed by users, as well as interactions with the content.
    </p>
    <p>
      Creative Ilk may also utilise user data for advertising communication purposes by tracking user interactions and conversions with any ads present on the site, including but not limited to ads powered by Google AdSense.
    </p>
    <h2>
      The rights of users
    </h2>
    <p>
      Users may exercise certain rights regarding their data which is processed by Creative Ilk, subject to exceptions allowed by law. These may include: withdrawing email marketing consent at any time by clicking unsubscribe on the promotional emails Creative Ilk send you; accessing their data by contacting Creative Ilk; verifying and seeking rectification; having personal data deleted or otherwise removed. Users requesting access to their personal information may be required to put their request in writing for security reasons. Creative Ilk reserves the right to charge a fee for searching for, and providing access to, your information on a per request basis.
    </p>
    <h2>
      Changes to this Privacy Policy
    </h2>
    <p>
      Creative Ilk reserves the right to make amendments to this Privacy Policy at any time. if you have objections to the Privacy Policy, you should not access or use the Site. It is recommended to check this page often.
    </p>
    <h2>
      Changes to this Privacy Policy
    </h2>
    <p>
      Creative Ilk reserves the right to make amendments to this Privacy Policy at any time. if you have objections to the Privacy Policy, you should not access or use the Site. It is recommended to check this page often.
    </p>
  </Layout>
)

export default PrivacyPolicy
